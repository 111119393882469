
COEF_PER_PIPE = 1.35
COEF = 0.45
STEP_LAST_PIPE = 10
DIDG_NAME = null
DIDG_SELECT = null
SCALE = 10
NB_CONFIG_DIDG = 1
DIDG_INFOS = null
TONES_RANGE = null
TONES_MAP = null
LANGUAGE = 'fr'
PAGE = null
FORBIDDEN_CHAR = ['<','>','$']

COEF_NOTE = 2**(1/12.0)

HOST = ''
MAX_CONFIGS = 0
MAX_SELECT = 0
SCALE_MM = 1
SCALE_CM = 10
NO_MAX_HARMONICS = ""
MAX_AIGUE = ""
MAX_BASSE = ""
COEF_NOTE = 2**(1/12.0)

charts = {}

NOTES_OCTAVE = ["C0","C#0","D0","D#0","E0","F0","F#0","G0","G#0","A0","A#0","B0","C1","C#1","D1","D#1","E1","F1","F#1","G1","G#1","A1","A#1","B1","C2","C#2","D2","D#2","E2","F2","F#2","G2","G#2","A2","A#2","B2","C3","C#3","D3","D#3","E3","F3","F#3","G3","G#3","A3","A#3","B3","C4","C#4","D4","D#4","E4","F4","F#4","G4","G#4","A4","A#4","B4","C5","C#5","D5","D#5","E5","F5","F#5","G5","G#5","A5","A#5","B5"]

MAX_LENGTH_DISPLAY = 2200

define_csts = function(host,max_configs,max_select,scale_mm,scale_cm,no_max_harmonics,max_aigue,max_basse) {
    HOST = host
    MAX_CONFIGS = max_configs
    MAX_SELECT = max_select
    SCALE_MM = scale_mm
    SCALE_CM = scale_cm
    NO_MAX_HARMONICS = no_max_harmonics
    MAX_AIGUE = max_aigue
    MAX_BASSE = max_basse
}

load_language = function(language) {
    LANGUAGE = language
}

valid_input = function(input) {
    var c,i
    for (i=0;i<FORBIDDEN_CHAR.length;i++){
        c = FORBIDDEN_CHAR[i]
        if (input.includes(c)) {
            return false
        }
    }
    return true
}

ecart_tones = function(tone_1,tone_2) {
    return (NOTES_OCTAVE.indexOf(tone_2)-NOTES_OCTAVE.indexOf(tone_1))/2
}

ecart_tones_frac_str = function(tone_1,tone_2) {
    var diff = ecart_tones(tone_1,tone_2)
    var res = ""
    if (diff > 6) {
        res = "2+"
        diff -= 6
    }
    if (diff == 1)
        res = res + "9/8"
    else if (diff == 1.5)
        res = res + "6/5"
    else if (diff == 2)
        res = res + "5/4"
    else if (diff == 2.5)
        res = res + "4/3"
    else if (diff == 3.5)
        res = res + "3/2"
    else if (diff == 4)
        res = res + "8/5"
    else if (diff == 4.5)
        res = res + "5/3"
    else if (diff == 5)
        res = res + "9/5"
    else if (diff == 5.5)
        res = res + "15/8"
    else if (diff == 6)
        res = res + "2"
    else
        res = ""
    return res
}

load_configure_constants = function(didg_name,didgs_info,scale) {
    DIDG_NAME = []
    SCALE = []
    var i
    for (i=0;i<MAX_CONFIGS;i++) {
        DIDG_NAME.push(didg_name)
        SCALE.push(scale)
    }
    DIDG_INFOS = didgs_info
    PAGE = 'configure'
    var elems,elem
    if (browser_pitch_detect_ok()) {
        elems = document.getElementsByClassName('tuto_accordeur_ko')
        for (i=0;i<elems.length;i++) {
            elem=elems[i]
            elem.style.display = 'none'
        }
    } else {
        elems = document.getElementsByClassName('tuto_accordeur_ok')
        for (i=0;i<elems.length;i++) {
            elem=elems[i]
            elem.style.display = 'none'
        }
    }
}

load_select_constants = function(didg_name,didgs_info,tones_range,tones_map) {
    DIDG_SELECT = didg_name
    DIDG_INFOS = didgs_info
    TONES_RANGE = tones_range
    TONES_MAP = tones_map
    PAGE = 'select'
    var i,elem,elems
    if (browser_pitch_detect_ok()) {
        elems = document.getElementsByClassName('tuto_accordeur_ko')
        for (i=0;i<elems.length;i++) {
            elem=elems[i]
            elem.style.display = 'none'
        }
    } else {
        elems = document.getElementsByClassName('tuto_accordeur_ok')
        for (i=0;i<elems.length;i++) {
            elem=elems[i]
            elem.style.display = 'none'
        }
    }
}

load_didgeridoo_constants = function(didgs_info,scale) {
    DIDG_INFOS = didgs_info
    SCALE = [scale]
    DIDG_NAME = ['4M']
    PAGE = 'didgeridoo'
}

load_user_constants = function(didgs_info) {
    DIDG_INFOS = didgs_info
    PAGE = 'user'
}

adapt_configure_scale = function(elem,id) {
    var i,j,length_select,l,opt,config = read_didg_config(id)
    for (i=0;i<config.length;i++) {
        config[i] = Math.round(config[i] / 10) * 10
    }
    if (elem.value == 'mm'){
        SCALE[id] = SCALE_MM
    } else {
        SCALE[id] = SCALE_CM
    }
    for (i=1;i<= DIDG_INFOS[DIDG_NAME[id]]['nb_config_pipes'];i++) {
        l = "length_" + id + "_" + i
        length_select = document.getElementById(l)
        while (length_select.options.length > 0) {
            length_select.remove(length_select.options.length - 1)
        }
        for (j=0;j<=Math.round(DIDG_INFOS[DIDG_NAME[id]]['config_sizes'][i - 1] / SCALE[id]);j++) {
            opt = document.createElement('option');
            opt.text = j;
            opt.value = j;
            length_select.add(opt, null);
        }
        if (elem.value == 'mm') {
            length_select.value = config[i - 1]
        }else {
            length_select.value = Math.round(config[i - 1] / 10)
        }
    }
    update_didg(id,'didg_canvas_' + id)
}

read_didg_config = function(id) {
    var i,l,select,len,config = []
    for (i=1;i<=DIDG_INFOS[DIDG_NAME[id]]['nb_config_pipes'];i++) {
        l = "length_" + id + "_" + i
        select = document.getElementById(l)
        if (select) {
            len = SCALE[id] * select.options[select.selectedIndex].value
            config.push(len)
        }
    }
    if (config.length != DIDG_INFOS[DIDG_NAME[id]]['nb_config_pipes']) {
        config = null
    }
    return config
}

read_didg_config_select = function(id) {
    var i,res = document.getElementById('config_val_' + id).innerHTML.split(',')
    for (i=0;i<res.length;i++) {
        res[i] = parseInt(res[i])
    }
    return res
}

init_didgs = function(config_array,diff_conf,new_conf,mobile=false) {
    var i
    for (i=0;i<MAX_CONFIGS;i++) {
        set_confuguration_select(i, null)
        set_config_values(i, config_array)
    }

    update_didg(0,'didg_canvas_0',mobile)
    NB_CONFIG_DIDG = 1
    for (i=1;i<MAX_CONFIGS;i++) {
        document.getElementById('didgeridoo_configuration_' + i).style.display = "none"
    }
    if (diff_conf != '') {
        document.getElementById('etalonnage_' + 0).style.display = "block"
        document.getElementById('adaptation_config_' + 0).style.display = "block"
        document.getElementById('nouvelle_config_' + 0).style.display = "block"
        document.getElementById('adaptation_config_val_' + 0).innerHTML = diff_conf
        document.getElementById('nouvelle_config_val_' + 0).innerHTML = new_conf
    }
}

update_didg = function(id,canvas_id,mobile=false) {
    var old = COEF
    draw_didg(id,canvas_id,null,DIDG_NAME[id])
    update_impedance_curve(id,mobile)
    COEF = old
}

copy_didg = function(id_old,id) {
    draw_didg(id,'didg_canvas_' + id,null,DIDG_NAME[id])
    copy_configuration_info(id_old,id)
    copy_tone_differences(id_old,id)
    clone_chart('canvas_impedance_' + id_old, 'canvas_impedance_' + id)
    clone_chart('canvas_harmonics_' + id_old, 'canvas_harmonics_' + id)
}

copy_tone_differences = function(id_pred,id) {
    var canvas_prec = document.getElementById('graduation_canvas_' + id_pred)
    var canvas = document.getElementById('graduation_canvas_' + id)
    var context
    if (canvas) {
        context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(canvas_prec, 0, 0);
    }else if (document.getElementById('ecart_1_config_' + id)) {
        document.getElementById('ecart_1_config_' + id).innerHTML = document.getElementById('ecart_1_config_' + id_pred).innerHTML
        document.getElementById('ecart_2_config_' + id).innerHTML = document.getElementById('ecart_2_config_' + id_pred).innerHTML
        document.getElementById('ecart_3_config_' + id).innerHTML = document.getElementById('ecart_3_config_' + id_pred).innerHTML
    }
}

update_didg_model = function(canvas_id,mobile=false) {
    var old = COEF
    draw_didg(0,canvas_id,null,DIDG_NAME[0])
    update_impedance_curve(0,mobile)
    COEF = old
}

scroll_add = function() {
    var res = (window.pageYOffset || document.documentElement.scrollTop)
    if (document.getElementById('mobile_version')) {
        res -= document.getElementById('banner_mobile').offsetHeight + 5
    }
    return res
}

scroll_to_bottom = function(id) {
    var top = document.getElementById(id).getBoundingClientRect().y + scroll_add() + document.getElementById(id).offsetHeight - window.innerHeight + document.getElementById('banner_mobile').offsetHeight
    window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
    })
}

configure_model = function(didg_name) {
    document.getElementById('didgeridoo_model_configuration').style.display = 'block'
    DIDG_NAME[0] = didg_name
    set_confuguration_select(0,null)
    update_didg(0,"didg_canvas_" + 0)
    window.scrollTo({
        top: document.getElementById('didgeridoo_model_configuration').getBoundingClientRect().y + scroll_add(),
        left: 0,
        behavior: 'smooth'
    })
}

change_config_didg = function(elem,id) {
    DIDG_NAME[id] = elem.value
    set_confuguration_select(id,null)
    update_didg(id,"didg_canvas_" + id)
}

change_select_didg = function(elem) {
    DIDG_SELECT = elem.value
    if (DIDG_SELECT.substring(0,3) == "Dou" || DIDG_SELECT.substring(0,3) == "Tri") {
        document.getElementById('max_harmonics').style.display = 'none'
    }else {
        document.getElementById('max_harmonics').style.display = 'block'
    }
    if (DIDG_SELECT.substring(0,3) == "Sex" || DIDG_SELECT.substring(0,3) == "Sep") {
        document.getElementById('tone_limitation').style.display = 'block'
    } else {
        document.getElementById('tone_limitation').style.display = 'none'
    }
    change_tone_selection_didg()
    for (i=0;i<MAX_SELECT;i++) {
        document.getElementById('config_' + i).style.display = 'none'
        toggle_impedance(i)
    }
}

init_select = function() {
    var i
    for (i=0;i<MAX_SELECT;i++) {
        document.getElementById('config_' + i).style.display = 'none'
        toggle_impedance(i)
    }
    if (DIDG_SELECT.substring(0,3) == "Dou" || DIDG_SELECT.substring(0,3) == "Tri") {
        document.getElementById('max_harmonics').style.display = 'none'
    } else{
        document.getElementById('max_harmonics').style.display = 'block'
    }
    if (DIDG_SELECT.substring(0,3) == "Sex" || DIDG_SELECT.substring(0,3) == "Sep") {
        document.getElementById('tone_limitation').style.display = 'block'
    } else {
        document.getElementById('tone_limitation').style.display = 'none'
    }
}

configure_selected = function(selected_id) {
    var config_id = parseInt(document.getElementById('config_id_' + selected_id).innerHTML)
    window.location.replace(HOST + '/phonowave/editer_didg?config_id=' + config_id)
}

save_selected = function(user_id,selected_id) {
    var config = document.getElementById('config_val_' + selected_id).innerHTML.split(',')
    var req = HOST + "/user_config_exists"
    var info = "didg_name=" + DIDG_SELECT
    var i,t_yes,t_no,elem,width
    for (i=1;i<=DIDG_INFOS[DIDG_SELECT]['nb_config_pipes'];i++) {
        info += "&l" + i + '=' + parseInt(config[i - 1])
    }
    Rails.ajax({
        url: req,
        type: "POST",
        data: info,
        success: function(response) {
            if (response['json'] && (document.getElementById('etalonnage_' + selected_id).style.display == 'none' || document.getElementById('adaptation_config_' + selected_id).style.display == 'none')) {
                send_message_jui(I18n.t("messages.already_saved"))
            }else if (document.getElementById('etalonnage_' + selected_id).style.display != 'none' && document.getElementById('adaptation_config_' + selected_id).style.display != 'none') {
                req = HOST + "/phonowave/save_user_config"
                document.getElementById('message_jui').innerHTML = I18n.t("messages.save_calibration")
                t_yes = I18n.t("messages.click_yes")
                t_no = I18n.t("messages.click_no")
                if ($("#message_jui").dialog("instance")) {
                    $("#message_jui").dialog("destroy")
                }
                elem = document.getElementById('message_div')
                if (document.getElementById('mobile_version')){
                    width = 'auto'
                } else {
                    width = 500
                }
                $("#message_jui").dialog({
                    position: {my: 'center', at: 'center', of: elem},
                    autoOpen: true,
                    resizable: false,
                    height: "auto",
                    width: width,
                    modal: true,
                    buttons: [
                        {
                            text: t_yes,
                            click: function() {
                                info += "&etalonnage=" + read_etalonnage(selected_id)
                                info += '&sample_f_0=' + document.getElementById('sample_0_' + selected_id).value
                                info += '&sample_f_1=' + document.getElementById('sample_1_' + selected_id).value
                                info += '&sample_f_2=' + document.getElementById('sample_2_' + selected_id).value
                                info += '&sample_f_3=' + document.getElementById('sample_3_' + selected_id).value
                                Rails.ajax({
                                    url: req,
                                    type: "POST",
                                    data: info,
                                    success: function(response) {send_message_jui(response['json'])}
                                });
                                $(this).dialog("close");
                            }
                        },
                        {
                            text : t_no,
                            click : function () {
                                Rails.ajax({
                                    url: req,
                                    type: "POST",
                                    data: info,
                                    success: function(response) {send_message_jui(response['json'])}
                                });
                                $(this).dialog("close");
                            }
                        }
                    ]
                });
            } else {
                req = HOST + "/phonowave/save_user_config"
                Rails.ajax({
                    url: req,
                    type: "POST",
                    data: info,
                    success: function(response) {send_message_jui(response['json'])}
                });
            }
        }
    });
}

ask_update_user_config = function(user_id,id) {
    var user_cong_loaded_0 = document.getElementById('user_config_loaded_0')
    var config = read_didg_config(id)
    var req = HOST + "/user_config_exists"
    var info = "didg_name=" + DIDG_NAME[id]
    var i, t_yes,t_no,elem,width
    for (i=1;i<=DIDG_INFOS[DIDG_NAME[id]]['nb_config_pipes'];i++) {
        info += "&l" + i + '=' + parseInt(config[i - 1])
    }
    Rails.ajax({
        url : req,
        type : "POST",
        data : info,
        success: function(response) {
            if (response['json'] && (document.getElementById('etalonnage_' + id).style.display == 'none' || document.getElementById('adaptation_config_' + id).style.display == 'none')) {
                send_message_jui(I18n.t("messages.already_saved"))
            }else {
                document.getElementById('message_jui').innerHTML = I18n.t("messages.update_config")
                t_yes = I18n.t("messages.click_yes")
                t_no = I18n.t("messages.click_no")
                if ($("#message_jui").dialog("instance")) {
                    $("#message_jui").dialog("destroy")
                }
                elem = document.getElementById('message_div')
                if (document.getElementById('mobile_version')) {
                    width = 'auto'
                }else {
                    width = 500
                }
                $("#message_jui").dialog({
                    position: {my: 'center', at: 'center', of: elem},
                    autoOpen: true,
                    resizable: false,
                    height: "auto",
                    width: width,
                    modal: true,
                    buttons: [
                        {
                            text: t_yes,
                            click: function() {
                                do_update_config(user_id,id)
                                $(this).dialog("close");
                            }
                        },
                        {
                            text : t_no,
                            click : function() {
                                save_config(user_id,id,false)
                                $(this).dialog("close");
                            }
                        }
                    ]
                });
            }
        }
    });
}

save_config = function(user_id,id,check_update=true) {
    var user_cong_loaded_0 = document.getElementById('user_config_loaded_0')
    if (check_update && id == 0 && user_cong_loaded_0){
        ask_update_user_config(user_id,id)
        return
    }
    var config = read_didg_config(id)
    var req = HOST + "/user_config_exists"
    var info = "didg_name=" + DIDG_NAME[id]
    var i, t_yes,t_no,elem,width
    for (i=1;i<=DIDG_INFOS[DIDG_NAME[id]]['nb_config_pipes'];i++) {
        info += "&l" + i + '=' + parseInt(config[i - 1])
    }
    Rails.ajax({
        url : req,
        type : "POST",
        data : info,
        success: function(response) {
            if (response['json'] && (document.getElementById('etalonnage_' + id).style.display == 'none' || document.getElementById('adaptation_config_' + id).style.display == 'none')) {
                send_message_jui(I18n.t("messages.already_saved"))
            }else if (document.getElementById('etalonnage_' + id).style.display != 'none' && document.getElementById('adaptation_config_' + id).style.display != 'none') {
                req = HOST + "/phonowave/save_user_config"
                document.getElementById('message_jui').innerHTML = I18n.t("messages.save_calibration")
                t_yes = I18n.t("messages.click_yes")
                t_no = I18n.t("messages.click_no")
                if ($("#message_jui").dialog("instance")) {
                    $("#message_jui").dialog("destroy")
                }
                elem = document.getElementById('message_div')
                if (document.getElementById('mobile_version')) {
                    width = 'auto'
                }else {
                    width = 500
                }
                $("#message_jui").dialog({
                    position: {my: 'center', at: 'center', of: elem},
                    autoOpen: true,
                    resizable: false,
                    height: "auto",
                    width: width,
                    modal: true,
                    buttons: [
                        {
                            text: t_yes,
                            click: function() {
                                info += "&etalonnage=" + read_etalonnage(id)
                                info += '&sample_f_0=' + document.getElementById('sample_0_' + id).value
                                info += '&sample_f_1=' + document.getElementById('sample_1_' + id).value
                                info += '&sample_f_2=' + document.getElementById('sample_2_' + id).value
                                info += '&sample_f_3=' + document.getElementById('sample_3_' + id).value
                                Rails.ajax({
                                    url: req,
                                    type: "POST",
                                    data: info,
                                    success: function(response) {send_message_jui(response['json'])}
                                });
                                $(this).dialog("close");
                            }
                        },
                        {
                            text : t_no,
                            click : function() {
                                Rails.ajax({
                                    url: req,
                                    type: "POST",
                                    data: info,
                                    success: function(response) {send_message_jui(response['json'])}
                                });
                                $(this).dialog("close");
                            }
                        }
                    ]
                });
            }else {
                req = HOST + "/phonowave/save_user_config"
                Rails.ajax({
                    url: req,
                    type: "POST",
                    data: info,
                    success: function(response) {send_message_jui(response['json'])}
                });
            }
        }
    });
}

do_update_config = function(user_id,id) {
    var user_cong_loaded_0 = document.getElementById('user_config_loaded_0')
    var user_config_id = null
    if (user_cong_loaded_0 && id == 0){
        user_config_id = user_cong_loaded_0.innerHTML
    } else {
        return
    }
    var config = read_didg_config(id)
    var req = HOST + "/user_config_exists"
    var info = "didg_name=" + DIDG_NAME[id]
    var i, t_yes,t_no,elem,width
    for (i=1;i<=DIDG_INFOS[DIDG_NAME[id]]['nb_config_pipes'];i++) {
        info += "&l" + i + '=' + parseInt(config[i - 1])
    }
    info += "&user_config_id=" + user_config_id
    Rails.ajax({
        url : req,
        type : "POST",
        data : info,
        success: function(response) {
            if (response['json'] && (document.getElementById('etalonnage_' + id).style.display == 'none' || document.getElementById('adaptation_config_' + id).style.display == 'none')) {
                send_message_jui(I18n.t("messages.already_saved"))
            }else if (document.getElementById('etalonnage_' + id).style.display != 'none' && document.getElementById('adaptation_config_' + id).style.display != 'none') {
                req = HOST + "/phonowave/update_user_config"
                document.getElementById('message_jui').innerHTML = I18n.t("messages.save_calibration")
                t_yes = I18n.t("messages.click_yes")
                t_no = I18n.t("messages.click_no")
                if ($("#message_jui").dialog("instance")) {
                    $("#message_jui").dialog("destroy")
                }
                elem = document.getElementById('message_div')
                if (document.getElementById('mobile_version')) {
                    width = 'auto'
                }else {
                    width = 500
                }
                $("#message_jui").dialog({
                    position: {my: 'center', at: 'center', of: elem},
                    autoOpen: true,
                    resizable: false,
                    height: "auto",
                    width: width,
                    modal: true,
                    buttons: [
                        {
                            text: t_yes,
                            click: function() {
                                info += "&etalonnage=" + read_etalonnage(id)
                                info += '&sample_f_0=' + document.getElementById('sample_0_' + id).value
                                info += '&sample_f_1=' + document.getElementById('sample_1_' + id).value
                                info += '&sample_f_2=' + document.getElementById('sample_2_' + id).value
                                info += '&sample_f_3=' + document.getElementById('sample_3_' + id).value
                                Rails.ajax({
                                    url: req,
                                    type: "POST",
                                    data: info,
                                    success: function(response) {send_message_jui(response['json'])}
                                });
                                $(this).dialog("close");
                            }
                        },
                        {
                            text : t_no,
                            click : function() {
                                Rails.ajax({
                                    url: req,
                                    type: "POST",
                                    data: info,
                                    success: function(response) {send_message_jui(response['json'])}
                                });
                                $(this).dialog("close");
                            }
                        }
                    ]
                });
            }else {
                req = HOST + "/phonowave/update_user_config"
                Rails.ajax({
                    url: req,
                    type: "POST",
                    data: info,
                    success: function(response) {send_message_jui(response['json'])}
                });
            }
        }
    });
}

alert_new_account = function() {
    document.getElementById('message_jui').innerHTML = I18n.t("messages.please_register")
    var elem = document.getElementById('message_div')
    var height
    if ($( "#message_jui" ).dialog( "instance" )) {
        $("#message_jui").dialog("destroy")
    }
    if (document.getElementById('mobile_version')) {
        height = 'auto'
    } else {
        height = 90
    }
    $("#message_jui" ).dialog({
        position: {my : 'center', at: 'center', of: elem},
        height: height,
        width: 'auto',
        draggable: false,
        resizable: false,
        show: {
            effect: "fade",
            duration: 200
        },
        hide: {
            effect: "fade",
            duration: 200
        }
    });
}

send_message_jui = function(message) {
    document.getElementById('message_jui').innerHTML = message
    var elem = document.getElementById('message_div')
    var height
    if ($( "#message_jui" ).dialog( "instance" )) {
        $("#message_jui").dialog("destroy")
    }
    if (document.getElementById('mobile_version')) {
        height = 'auto'
    } else {
        height = 90
    }
    $("#message_jui" ).dialog({
        position: {my : 'center', at: 'center', of: elem},
        height: height,
        width: 'auto',
        draggable: false,
        resizable: false,
        show: {
            effect: "fade",
            duration: 200
        },
        hide: {
            effect: "fade",
            duration: 200
        }
    });
}

read_etalonnage = function(id) {
    var res = []
    var val,v,i
    if (document.getElementById("nouvelle_config_" + id).style.display != 'none') {
        val = document.getElementById("nouvelle_config_val_" + id).innerHTML.split(',')
        for (i=0;i<val.length;i++) {
            v=val[i]
            res.push(parseInt(v))
        }
    }
    return res
}

draw_didg = function(id,canvas_id,config,didg_name) {
    var draw_json = DIDG_INFOS[didg_name]
    var canvas = document.getElementById(canvas_id)
    var ctx, len,i,l, ratio, nb_pipes,x_init,y_init,diam_mouth,coord,diam_connect
    if (!config) {
        config = read_didg_config(id)
    }
    if (canvas.getContext && config) {
        ctx = canvas.getContext("2d")
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        len = 0
        for (i=0;i<config.length;i++) {
            len += config[i]
            len += draw_json['length_connect']
        }
        len += draw_json['last_pipe_length']
        l = len / (config.length + 1)
        ratio = canvas.width / 1000.0
        nb_pipes = config.length + 1
        if (nb_pipes == 2 || len < MAX_LENGTH_DISPLAY) {
            COEF = ratio * COEF_PER_PIPE / 3.2
        } else {
            COEF = ratio * COEF_PER_PIPE / 3.2 / ((len - MAX_LENGTH_DISPLAY) / MAX_LENGTH_DISPLAY + 1)
        }
        if (len < MAX_LENGTH_DISPLAY) {
            x_init = 50
            y_init = 80
        } else {
            x_init = 80 / ((len - MAX_LENGTH_DISPLAY) / MAX_LENGTH_DISPLAY + 1)
            y_init = 110 / ((len - MAX_LENGTH_DISPLAY) / MAX_LENGTH_DISPLAY + 1)
        }
        if (draw_json['diameters'].length > 2) {
            diam_mouth = (draw_json['diameters'][1] + draw_json['diameters'][2]) / 2
            coord = add_mouthpiece(ctx, draw_json['diameters'][0], diam_mouth, draw_json['nb_pipes'], x_init, y_init)
            for (i=0;i<config.length - 1;i++) {
                coord = add_pipe(ctx, coord, config[i], draw_json['diameters'][i])
                diam_connect = (draw_json['diameters'][i + 1] + draw_json['diameters'][i + 2]) / 2
                coord[1] -= Math.round((diam_connect - draw_json['diameters'][i]) / 2)
                coord = add_pipe(ctx, coord, draw_json['length_connect'], diam_connect)
                coord[1] += Math.round((diam_connect - draw_json['diameters'][i + 1]) / 2)
            }
        } else {
            diam_mouth = (draw_json['diameters'][0] + draw_json['diameters'][1]) / 2
            coord = add_mouthpiece(ctx, draw_json['diameters'][0], diam_mouth, draw_json['nb_pipes'], x_init, y_init)
        }
        coord = add_pipe(ctx, coord, config[config.length - 1], draw_json['diameters'][draw_json['diameters'].length - 2])
        coord = add_bell(ctx, coord, draw_json['diameters'][draw_json['diameters'].length - 2], draw_json['diameters'][draw_json['diameters'].length - 1], STEP_LAST_PIPE)
        coord = add_pipe(ctx, coord, draw_json['last_pipe_length'], draw_json['diameters'][draw_json['diameters'].length - 1])
        coord = add_bell(ctx, coord, draw_json['diameters'][draw_json['diameters'].length - 1], draw_json['bell_diameter'], draw_json['bell_length'])
    }
}

update_tone_differences = function(id,response) {
    var canvas = document.getElementById('graduation_canvas_' + id)
    WIDTH = 2600
    ADAPT = WIDTH / response['tone_max']
    var ctx, x_init, y_init, x, y, i
    if (canvas && canvas.getContext) {
        ctx = canvas.getContext("2d")
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        x_init = 0
        y_init = 120
        x = x_init
        y = y_init
        ctx.beginPath()
        ctx.moveTo(x, y)
        ctx.lineTo(x + WIDTH, y)
        ctx.stroke()
        for (i=0;i<4;i++) {
            ctx.moveTo(x_init + response['tone_ref'][i] * ADAPT, y_init - 15)
            ctx.lineTo(x_init + response['tone_ref'][i] * ADAPT, y_init + 15)
            ctx.stroke()
            ctx.font = "48px sans-serif";
            ctx.fillText(response['tones'][i], x_init + response['tone_ref'][i] * ADAPT - 15, y_init - 40);
            ctx.moveTo(x_init + response['tone_val'][i] * ADAPT, y_init)
            ctx.lineTo(x_init + response['tone_val'][i] * ADAPT, y_init + 50)
            ctx.stroke()
            ctx.font = "36px sans-serif";
            ctx.fillText(response['tones_name'][i], x_init + response['tone_ref'][i] * ADAPT - 80, y_init + 80);
            if (i > 0) {
                ctx.font = "40px sans-serif";
                ctx.fillText(response['difference'][i], x_init + ADAPT * (response['tone_ref'][i - 1] + response['tone_ref'][i]) / 2.0 - (response['difference'][i]).length * 8, y_init - 90);
                ctx.moveTo(x_init + ADAPT * response['tone_ref'][i - 1] + 90, y_init - 50)
                ctx.lineTo(x_init + ADAPT * response['tone_ref'][i] - 70, y_init - 50)
                ctx.stroke()
                ctx.moveTo(x_init + ADAPT * (response['tone_ref'][i - 1] + response['tone_ref'][i]) / 2.0 + 10, y_init - 50)
                ctx.lineTo(x_init + ADAPT * (response['tone_ref'][i - 1] + response['tone_ref'][i]) / 2.0 - 5, y_init - 65)
                ctx.stroke()
                ctx.moveTo(x_init + ADAPT * (response['tone_ref'][i - 1] + response['tone_ref'][i]) / 2.0 + 10, y_init - 50)
                ctx.lineTo(x_init + ADAPT * (response['tone_ref'][i - 1] + response['tone_ref'][i]) / 2.0 - 5, y_init - 35)
                ctx.stroke()
            }
        }
    } else if (document.getElementById('ecart_1_config_' + id)) {
        document.getElementById('ecart_1_config_' + id).innerHTML = response['difference'][1]
        document.getElementById('ecart_2_config_' + id).innerHTML = response['difference'][2]
        document.getElementById('ecart_3_config_' + id).innerHTML = response['difference'][3]
    }
}

add_mouthpiece = function(ctx,diameter,diam_mouth,nb_pipes,x_init,y_init) {
    var x = x_init
    var y = y_init
    var dif = (diam_mouth - 6) / 2.0
    ctx.beginPath()
    ctx.moveTo(x*COEF, y*COEF)
    ctx.lineTo((x-5)*COEF,y*COEF)
    ctx.arc((x-5)*COEF, (y+dif)*COEF, dif*COEF, -Math.PI/2,Math.PI,true)
    ctx.lineTo((x-5-dif)*COEF, (y+dif+6)*COEF)
    ctx.arc((x-5)*COEF, (y+dif+6)*COEF, dif*COEF, Math.PI,Math.PI/2,true)
    ctx.lineTo(x*COEF, (y+diam_mouth)*COEF)
    ctx.lineTo(x*COEF,y*COEF)
    ctx.fillStyle = "#aa873a"
    ctx.fill()
    return [x,y+((diam_mouth-diameter)/2)]
}

add_pipe = function(ctx,coord,size,diameter) {
    ctx.beginPath()
    var x = coord[0]
    var y = coord[1]
    ctx.moveTo(x*COEF,y*COEF)
    y = coord[1] + diameter
    ctx.lineTo(x*COEF,y*COEF)
    x = coord[0] + size
    ctx.lineTo(x*COEF,y*COEF)
    y = coord[1]
    ctx.lineTo(x*COEF,y*COEF)
    ctx.lineTo(coord[0]*COEF,coord[1]*COEF)
    ctx.fillStyle = "#404040"
    ctx.fill()
    return [x,y]
}

add_bell = function(ctx,coord,diam0,diam1,bell_length) {
    ctx.beginPath()
    var x = coord[0]
    var y = coord[1]
    ctx.moveTo(x*COEF,y*COEF)
    y = y + diam0
    ctx.lineTo(x*COEF,y*COEF)
    x = x + bell_length
    y = y + Math.round((diam1-diam0)/2)
    ctx.lineTo(x*COEF,y*COEF)
    y = y - diam1
    ctx.lineTo(x*COEF,y*COEF)
    ctx.lineTo(coord[0]*COEF,coord[1]*COEF)
    ctx.fillStyle = "#404040"
    ctx.fill()
    return [x,y]
}

update_impedance_curve = function(id,mobile=false) {
    var config = read_didg_config(id)
    var req = HOST + "/phonowave/update_impedance_curve"
    var info  = ""
    var i
    info = "didg_name=" + DIDG_NAME[id]
    for (i=1;i<=DIDG_INFOS[DIDG_NAME[id]]['nb_config_pipes'];i++) {
        info += "&l" + i + "=" + config[i - 1]
    }
    reset_configuration_result(id)
    Rails.ajax({
        url : req,
        type : "POST",
        data : info,
        success: function(response) {treat_impedance_info(response,id,mobile)}
    });
}

treat_impedance_info = function(response,id,mobile=false) {
    if (response.hasOwnProperty('json')) {
        update_configuration_info(id, response['json'], mobile)
        update_tone_differences(id, response['json']['tone_differences'])
        draw_impedance(response['json']['chart_js_impedance'], 'canvas_impedance_' + id)
        draw_harmonics(response['json']['chart_js_harmonics'], 'canvas_harmonics_' + id)
    } else {
        send_message_jui(I18n.t("messages.error_info"))
    }
}

reset_configuration_result = function(id) {
    document.getElementById('freq_' + id + '_0_config').innerHTML = ""
    document.getElementById('tone_' + id + '_0_config').innerHTML = ""
    document.getElementById('dev_' + id + '_0_config').innerHTML = ""
    document.getElementById('imp_' + id + '_0_config').innerHTML = ""
    document.getElementById('freq_' + id + '_1_config').innerHTML = ""
    document.getElementById('tone_' + id + '_1_config').innerHTML = ""
    document.getElementById('dev_' + id + '_1_config').innerHTML = ""
    document.getElementById('imp_' + id + '_1_config').innerHTML = ""
    document.getElementById('freq_' + id + '_2_config').innerHTML = ""
    document.getElementById('tone_' + id + '_2_config').innerHTML = ""
    document.getElementById('dev_' + id + '_2_config').innerHTML = ""
    document.getElementById('imp_' + id + '_2_config').innerHTML = ""
    document.getElementById('freq_' + id + '_3_config').innerHTML = ""
    document.getElementById('tone_' + id + '_3_config').innerHTML = ""
    document.getElementById('dev_' + id + '_3_config').innerHTML = ""
    document.getElementById('imp_' + id + '_3_config').innerHTML = ""
    if (PAGE != 'didgeridoo') {
        reset_etalonnage_config(id)
    }
    if (charts['canvas_impedance_' + id]) {
        charts['canvas_impedance_' + id].destroy()
    }
    if (charts['canvas_harmonics_' + id]) {
        charts['canvas_harmonics_' + id].destroy()
    }
}

reset_etalonnage_config = function(id) {
    document.getElementById('sample_0_' + id).value = ""
    document.getElementById('sample_1_' + id).value = ""
    document.getElementById('sample_2_' + id).value = ""
    document.getElementById('sample_3_' + id).value = ""
    document.getElementById('etalonnage_' + id).style.display = "none"
    document.getElementById('adaptation_config_' + id).style.display = "none"
    document.getElementById('nouvelle_config_' + id).style.display = "none"
    document.getElementById('adaptation_config_val_' + id).innerHTML = ""
    document.getElementById('nouvelle_config_val_' + id).innerHTML = ""
}

update_configuration_info = function(id,response,mobile=false) {
    var hz,ohm
    if (mobile) {
        hz = ""
        ohm = ""
    }else {
        hz = " Hz"
        ohm = " MOhm"
    }
    document.getElementById('freq_' + id + '_0_config').innerHTML = response['config_info']['freq'][0] + hz
    document.getElementById('tone_' + id + '_0_config').innerHTML = response['config_info']['tone'][0]
    document.getElementById('dev_' + id + '_0_config').innerHTML = response['config_info']['cents'][0] + "\%"
    document.getElementById('imp_' + id + '_0_config').innerHTML = response['config_info']['impedance'][0] + ohm
    document.getElementById('freq_' + id + '_1_config').innerHTML = response['config_info']['freq'][1] + hz
    document.getElementById('tone_' + id + '_1_config').innerHTML = response['config_info']['tone'][1]
    document.getElementById('dev_' + id + '_1_config').innerHTML = response['config_info']['cents'][1] + "\%"
    document.getElementById('imp_' + id + '_1_config').innerHTML = response['config_info']['impedance'][1] + ohm
    document.getElementById('freq_' + id + '_2_config').innerHTML = response['config_info']['freq'][2] + hz
    document.getElementById('tone_' + id + '_2_config').innerHTML = response['config_info']['tone'][2]
    document.getElementById('dev_' + id + '_2_config').innerHTML = response['config_info']['cents'][2] + "%"
    document.getElementById('imp_' + id + '_2_config').innerHTML = response['config_info']['impedance'][2] + ohm
    document.getElementById('freq_' + id + '_3_config').innerHTML = response['config_info']['freq'][3] + hz
    document.getElementById('tone_' + id + '_3_config').innerHTML = response['config_info']['tone'][3]
    document.getElementById('dev_' + id + '_3_config').innerHTML = response['config_info']['cents'][3] + "%"
    document.getElementById('imp_' + id + '_3_config').innerHTML = response['config_info']['impedance'][3] + ohm

}

copy_configuration_info = function(id_prec,id) {
    document.getElementById('freq_' + id + '_0_config').innerHTML = document.getElementById('freq_' + id_prec + '_0_config').innerHTML
    document.getElementById('tone_' + id + '_0_config').innerHTML = document.getElementById('tone_' + id_prec + '_0_config').innerHTML
    document.getElementById('dev_' + id + '_0_config').innerHTML = document.getElementById('dev_' + id_prec + '_0_config').innerHTML
    document.getElementById('imp_' + id + '_0_config').innerHTML = document.getElementById('imp_' + id_prec + '_0_config').innerHTML
    document.getElementById('freq_' + id + '_1_config').innerHTML = document.getElementById('freq_' + id_prec + '_1_config').innerHTML
    document.getElementById('tone_' + id + '_1_config').innerHTML = document.getElementById('tone_' + id_prec + '_1_config').innerHTML
    document.getElementById('dev_' + id + '_1_config').innerHTML = document.getElementById('dev_' + id_prec + '_1_config').innerHTML
    document.getElementById('imp_' + id + '_1_config').innerHTML = document.getElementById('imp_' + id_prec + '_1_config').innerHTML
    document.getElementById('freq_' + id + '_2_config').innerHTML = document.getElementById('freq_' + id_prec + '_2_config').innerHTML
    document.getElementById('tone_' + id + '_2_config').innerHTML = document.getElementById('tone_' + id_prec + '_2_config').innerHTML
    document.getElementById('dev_' + id + '_2_config').innerHTML = document.getElementById('dev_' + id_prec + '_2_config').innerHTML
    document.getElementById('imp_' + id + '_2_config').innerHTML = document.getElementById('imp_' + id_prec + '_2_config').innerHTML
    document.getElementById('freq_' + id + '_3_config').innerHTML = document.getElementById('freq_' + id_prec + '_3_config').innerHTML
    document.getElementById('tone_' + id + '_3_config').innerHTML = document.getElementById('tone_' + id_prec + '_3_config').innerHTML
    document.getElementById('dev_' + id + '_3_config').innerHTML = document.getElementById('dev_' + id_prec + '_3_config').innerHTML
    document.getElementById('imp_' + id + '_3_config').innerHTML = document.getElementById('imp_' + id_prec + '_3_config').innerHTML

}

clone_chart = function(old_canvas,new_canvas) {
    var canvas = document.getElementById(new_canvas)
    var ctx
    if (canvas) {
        ctx = canvas.getContext('2d')
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (charts[new_canvas]) {
                charts[new_canvas].destroy()
            }
            charts[new_canvas] = new Chart(ctx, {
                type: charts[old_canvas].config.type,
                data: charts[old_canvas].config.data,
                options: charts[old_canvas].config.options
            });
        }
    }
}

draw_impedance = function(response,canvas_id) {
    var canvas = document.getElementById(canvas_id)
    var ctx
    if (canvas) {
        ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (charts[canvas_id]){
                charts[canvas_id].destroy()
           }
            charts[canvas_id] = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: [{
                        label: "Cents",
                        hidden: true,
                        data: response['cents'],
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        borderColor: 'rgba(0, 0, 0, 0.1)'
                        },
                        {
                        label: "Note",
                        hidden: true,
                        data: response['tone'],
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        borderColor: 'rgba(0, 0, 0, 0.1)'
                        },
                        {
                        label: "Impédance",
                        data: response['impedance'],
                        backgroundColor: 'rgba(4, 0, 124, 0.4)',
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        fill: true
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            scaleLabel: {
                                display: true,
                                labelString: I18n.t("charts_axis.imp_axis")
                            }
                        }],
                        xAxes: [{
                            type: 'linear',
                            ticks: {
                                beginAtZero: true
                            },
                            scaleLabel: {
                                display: true,
                                labelString: I18n.t("charts_axis.x_axis")
                            }
                        }]
                    },
                    elements: {
                        point:{
                            backgroundColor: 'rgba(0, 0, 0, 0.0)',
                            borderColor: 'rgba(0, 0, 0, 0.0)',
                            radius: 5
                        }
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        displayColors: false,
                        callbacks: {
                            title: function(tooltipItems, data) {
                                return ''
                            },
                            beforeLabel: function(tooltipItem, data) {
                                cents = data.datasets[0].data[tooltipItem.index]['y']
                                tone = data.datasets[1].data[tooltipItem.index]['y']
                                return tone + " (" + cents + "%)"
                            },
                            label: function(tooltipItem, data) {
                                return I18n.t("charts_axis.frequency") + ' : ' + tooltipItem.xLabel + ' Hz'
                            },
                            afterLabel: function(tooltipItem, data) {
                                return I18n.t("charts_axis.impedance") + ' : ' + tooltipItem.yLabel + ' MOhm'
                            }
                        }
                    }
                }
            });
        }
    }
}

draw_harmonics = function(response,canvas_id) {
    var ctx, canvas = document.getElementById(canvas_id)
    if (canvas) {
        ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (charts[canvas_id]) {
                charts[canvas_id].destroy()
            }
            charts[canvas_id] = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: [{
                        label: "Cents",
                        hidden: true,
                        data: response['cents']
                    },
                        {
                            label: "Note",
                            hidden: true,
                            data: response['tone']
                        },
                        {
                            label: "Harmoniques",
                            data: response['volume'],
                            backgroundColor: 'rgba(4, 0, 124, 0.4)',
                            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                            fill: true
                        }
                        ,
                        {
                            label: "Harmoniques maximum",
                            hidden: true,
                            data: response['maximum'],
                            pointRadius: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            borderColor: 'rgba(0, 0, 0, 0.5)',
                            fill: false
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            scaleLabel: {
                                display: true,
                                labelString: I18n.t("charts_axis.harm_axis")
                            }
                        }],
                        xAxes: [{
                            type: 'linear',
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(item) {
                                    return 2 ** item + response['min_f_harmonics'] - 1
                                }
                            },
                            scaleLabel: {
                                display: true,
                                labelString: I18n.t("charts_axis.x_axis")
                            }
                        }]
                    },
                    elements: {
                        point: {
                            backgroundColor: 'rgba(255, 255, 255, 0.0)',
                            borderColor: 'rgba(255, 255, 255, 0.0)'
                        }
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        displayColors: false,
                        callbacks: {
                            title: function(tooltipItems, data) {
                                return ''
                            },
                            beforeLabel: function(tooltipItem, data) {
                                cents = data.datasets[0].data[tooltipItem.index]['y']
                                tone = data.datasets[1].data[tooltipItem.index]['y']
                                return tone + " (" + cents + "%)"
                            },
                            label: function(tooltipItem, data) {
                                return I18n.t("charts_axis.frequency") + ' : ' + Math.round(2 ** tooltipItem.xLabel + response['min_f_harmonics'] - 1).toFixed(1) + ' Hz'
                            },
                            afterLabel: function(tooltipItem, data) {
                                return I18n.t("charts_axis.volume") + ' : ' + tooltipItem.yLabel + ' dB'
                            }
                        }
                    }
                }
            });
        }
    }
}

read_tone_selection = function() {
    var i, t,tone, select,selection = []
    for (i=0;i<4;i++) {
        t = "tone_P"
        t = t.replace("P", i)
        select = document.getElementById(t)
        tone = select.options[select.selectedIndex].value
        selection.push(tone)
    }
    return selection
}

check_tone_feasible = function(selection) {
    var i,current = TONES_MAP[DIDG_SELECT]
    for (i=0;i<4;i++) {
        if (Object.keys(current).includes(selection[i]))
            current = current[selection[i]]
        else
            return false
    }
    return true
}

change_tone_selection_didg = function() {
    var i,j,elem,flag,opt,tone,j,selection = read_tone_selection()
    for (i=0;i<4;i++) {
        elem = document.getElementById('tone_' + i)
        while (elem.options.length > 0) {
            elem.remove(elem.options.length - 1)
        }
        flag = false
        for (j=0;j<TONES_RANGE[DIDG_SELECT][i].length;j++) {
            tone = TONES_RANGE[DIDG_SELECT][i][j]
            opt = document.createElement('option');
            opt.text = tone;
            opt.value = tone;
            elem.add(opt, null);
            if (tone == selection[i])
                flag = true
        }
        if (flag)
            elem.value = selection[i]
        else
            elem.value = "-"
    }
    change_tone_selection()
    for (i=0;i<4;i++) {
        elem = document.getElementById('tone_' + i)
        for (j=0;j<elem.options.length;j++) {
            if (elem.value == elem.options[j].value && elem.options[j].disabled)
            {
                elem.value = "-"
            }
        }
    }
    change_tone_selection()
}

reset_tone_selection = function() {
    for (i=0;i<4;i++) {
        select = document.getElementById('tone_' + i).value = "-"
    }
    change_tone_selection_didg()
    for (i=1;i<4;i++) {
        document.getElementById('impedance_max_' + i).checked = false
    }
    document.getElementById('maximize_harmonics').value = "-"
}

change_tone_selection = function() {
    var i,old,j,tone,element,ecart,selection = read_tone_selection()
    for (i=0;i<4;i++) {
        old = selection[i]
        for (j=0;j<(TONES_RANGE[DIDG_SELECT][i].length);j++) {
            selection[i] = TONES_RANGE[DIDG_SELECT][i][j]
            if (i > 0) {
                tone = document.getElementById('tone_' + i).options[j].innerHTML.split(' ')[0]
                document.getElementById('tone_' + i).options[j].innerHTML = tone
            }
            if (check_tone_feasible(selection)) {
                element = document.getElementById('tone_' + i).options[j].disabled = false
                if (i > 0 && selection[i - 1] != '-' && selection[i] != '-') {
                    ecart = ecart_tones_frac_str(selection[i - 1], selection[i])
                    if (ecart.length > 0) {
                        tone = document.getElementById('tone_' + i).options[j].innerHTML
                        document.getElementById('tone_' + i).options[j].innerHTML = tone + " - " + ecart
                    }
                }
            } else {
                element = document.getElementById('tone_' + i).options[j].disabled = true
            }
        }
        selection[i] = old
    }
}

display_config = function(response,id) {
    var config,i,old,elem,ratio
    if (id < MAX_SELECT) {
        config = response['configs'][id]['config'].split(',')
        for (i=0;i<config.length;i++) {
            config[i] = parseInt(config[i])
        }
        old = COEF
        elem = document.getElementById('didg_canvas_' + id)
        ratio = elem.width / 1000.0
        COEF = ratio * COEF_PER_PIPE / (config.length + 1)
        draw_didg(null, "didg_canvas_" + id, config, response['didg_name'][id])
        draw_impedance(response['impedance_curve'][id], 'canvas_impedance_' + id)
        draw_harmonics(response['curve'][id], 'canvas_harmonics_' + id)
        COEF = old

        return true
    }
  return false
}

update_config_info_id = function(config_json,id,mobile=false) {
    var hz,ohm
    if (mobile) {
        hz = ""
        ohm = ""
    } else {
        hz = " Hz"
        ohm = " MOhm"
    }
    document.getElementById('config_val_' + id).innerHTML = config_json['config']
    document.getElementById('config_id_' + id).innerHTML = config_json['id']
    document.getElementById('freq_0_config_' + id).innerHTML = config_json['f_0'] + hz
    document.getElementById('tone_0_config_' + id).innerHTML = config_json['tone_0']
    document.getElementById('dev_0_config_' + id).innerHTML = config_json['cents_0'] + "\%"
    document.getElementById('imp_0_config_' + id).innerHTML = config_json['imp_0'] + ohm
    document.getElementById('freq_1_config_' + id).innerHTML = config_json['f_1'] + hz
    document.getElementById('tone_1_config_' + id).innerHTML = config_json['tone_1']
    document.getElementById('dev_1_config_' + id).innerHTML = config_json['cents_1'] + "\%"
    document.getElementById('imp_1_config_' + id).innerHTML = config_json['imp_1'] + ohm
    document.getElementById('freq_2_config_' + id).innerHTML = config_json['f_2'] + hz
    document.getElementById('tone_2_config_' + id).innerHTML = config_json['tone_2']
    document.getElementById('dev_2_config_' + id).innerHTML = config_json['cents_2'] + "%"
    document.getElementById('imp_2_config_' + id).innerHTML = config_json['imp_2'] + ohm
    document.getElementById('freq_3_config_' + id).innerHTML = config_json['f_3'] + hz
    document.getElementById('tone_3_config_' + id).innerHTML = config_json['tone_3']
    document.getElementById('dev_3_config_' + id).innerHTML = config_json['cents_3'] + "%"
    document.getElementById('imp_3_config_' + id).innerHTML = config_json['imp_3'] + ohm
    return true
}

option_harmonics = function() {
    var elem = document.getElementById('maximize_harmonics')
    if (elem.value == I18n.t('didg_tuner.select_tones.low')){
        return MAX_BASSE
    } else if (elem.value == I18n.t('didg_tuner.select_tones.no_option')) {
        return NO_MAX_HARMONICS
    }
    return MAX_AIGUE
}

update_selection = function(mobile=false) {
    var i, selection,req,info,elem
    for (i=0;i<MAX_SELECT;i++) {
        document.getElementById('config_' + i).style.display = 'none'
        toggle_impedance(i)
    }
    selection = read_tone_selection()
    req = HOST + "/phonowave/fetch_configs"
    info = "didg_name=" + DIDG_SELECT
    for (i=0;i<4;i++) {
        info += "&t_" + i + "=" + selection[i].replace("#", "_")
    }
    harmonics = option_harmonics()
    info += "&harmonics=" + harmonics
    for (i=1;i<4;i++) {
        elem = document.getElementById('impedance_max_' + i)
        if (elem.checked)
            info += "&imp_" + i + '=' + 1
        else
            info += "&imp_" + i + '=' + 0
    }
    document.getElementById('select_loading').style.display = 'block'
    window.scrollTo({
        top: document.getElementById('select_loading').getBoundingClientRect().y + scroll_add(),
        left: 0,
        behavior: 'smooth'
    })
    Rails.ajax({
        url : req,
        type : "POST",
        data : info,
        success: function(response) {treat_selection(response,mobile)}
    });
}

treat_selection = function(response,mobile=false) {
    document.getElementById('select_loading').style.display = 'none'
    var i
    if (response.hasOwnProperty('json')) {
        if (response['json']['configs'].length > 0) {
            for (i=0;i<response['json']['configs'].length;i++) {
                reset_etalonnage_config(i)
                deactivate_tuner(i)
                if (display_config(response['json'], i) && update_config_info_id(response['json']['configs'][i], i, mobile)) {
                    update_tone_differences(i, response['json']['differences'][i])
                    document.getElementById('config_' + i).style.display = 'block'
                }
            }
        }
        window.scrollTo({
            top: document.getElementById('select_result').getBoundingClientRect().y + scroll_add(),
            left: 0,
            behavior: 'smooth'
        })
    }else {
        send_message_jui(I18n.t("messages.error_search"))
    }
}

toggle_display = function(id) {
    var elem = document.getElementById(id)
    var display = elem.style.display
    if (display == "none") {
        elem.style.display = "block"
        if (document.getElementById('mobile_version'))
            scroll_to_bottom(id)
    } else {
        elem.style.display = "none"
    }
}

add_config = function(id) {
    var elem_prec, elem, i, val
    if (document.getElementById('freq_' + (id - 1) + '_0_config').innerHTML != ""){
        if (NB_CONFIG_DIDG == 1) {
            document.getElementById('remove_config_0').style.display = "block"
        }
        document.getElementById('didgeridoo_configuration_' + id).style.display = "block"
        document.getElementById('add_config_' + (id - 1)).style.display = "none"
        document.getElementById('remove_config_' + id).style.display = "block"

        elem_prec = document.getElementById('didg_select_' + (id - 1))
        elem = document.getElementById('didg_select_' + id)
        elem.value = elem_prec.value
        DIDG_NAME[id] = elem.value
        set_confuguration_select(id, id - 1)
        for (i=1;i<=DIDG_INFOS[DIDG_NAME[id]]['nb_config_pipes'];i++) {
            val = document.getElementById('length_' + (id - 1) + '_' + i).value
            elem = document.getElementById('length_' + id + '_' + i)
            elem.value = val
        }
        copy_didg(id - 1, id)
        window.scrollTo({
            top: document.getElementById('didgeridoo_configuration_' + id).getBoundingClientRect().y + scroll_add(),
            left: 0,
            behavior: 'smooth'
        })
        NB_CONFIG_DIDG += 1
        reset_etalonnage_config(id)
    }
}

remove_config = function(id) {
    var i,j,val,elem
    NB_CONFIG_DIDG -= 1
    document.getElementById('didgeridoo_configuration_' + NB_CONFIG_DIDG).style.display = "none"
    document.getElementById('add_config_' + (NB_CONFIG_DIDG-1)).style.display = "inline"
    document.getElementById('remove_config_' + NB_CONFIG_DIDG).style.display = "block"
    if (NB_CONFIG_DIDG == 1) {
        document.getElementById('remove_config_0').style.display = "none"
    }
    for (i=id+1;i<=NB_CONFIG_DIDG+1;i++) {
        document.getElementById('didg_select_' + (i - 1)).value = document.getElementById('didg_select_' + i).value
        document.getElementById('sample_0_' + (i - 1)).value = document.getElementById('sample_0_' + i).value
        document.getElementById('sample_1_' + (i - 1)).value = document.getElementById('sample_1_' + i).value
        document.getElementById('sample_2_' + (i - 1)).value = document.getElementById('sample_2_' + i).value
        document.getElementById('adaptation_config_val_' + (i - 1)).innerHTML = document.getElementById('adaptation_config_val_' + (i)).innerHTML
        document.getElementById('nouvelle_config_val_' + (i - 1)).innerHTML = document.getElementById('nouvelle_config_val_' + (i)).innerHTML
        document.getElementById('courbe_impedance_' + (i - 1)).style.display = document.getElementById('courbe_impedance_' + i).style.display
        document.getElementById('courbe_harmonics_' + (i - 1)).style.display = document.getElementById('courbe_harmonics_' + i).style.display
        if (document.getElementById('samples_col_' + i).style.display == 'none') {
            reset_etalonnage_config(i - 1)
            deactivate_tuner(i - 1)
        } else {
            etalonner_config(i - 1)
        }
        if (document.getElementById('etalonnage_' + i).style.display == 'none') {
            document.getElementById('etalonnage_' + (i - 1)).style.display = 'none'
            document.getElementById('nouvelle_config_' + (i - 1)).style.display = 'none'
            document.getElementById('adaptation_config_' + (i - 1)).style.display = 'none'
        } else {
            document.getElementById('etalonnage_' + (i - 1)).style.display = 'block'
            document.getElementById('nouvelle_config_' + (i - 1)).style.display = 'block'
            document.getElementById('adaptation_config_' + (i - 1)).style.display = 'block'
        }
        DIDG_NAME[i - 1] = DIDG_NAME[i]
        set_confuguration_select(i - 1, i)
        for (j=1;j<=DIDG_INFOS[DIDG_NAME[i - 1]]['nb_config_pipes'];j++) {
            val = document.getElementById('length_' + i + '_' + j).value
            elem = document.getElementById('length_' + (i - 1) + '_' + j)
            elem.value = val
        }
        if (i < NB_CONFIG_DIDG + 1)
            copy_didg(i, i - 1)
    }
    reset_etalonnage_config(NB_CONFIG_DIDG+1)
    deactivate_tuner(NB_CONFIG_DIDG+1)
    document.getElementById('etalonnage_' + (NB_CONFIG_DIDG+1)).style.display = 'none'
    document.getElementById('nouvelle_config_' + (NB_CONFIG_DIDG+1)).style.display = 'none'
    document.getElementById('adaptation_config_' + (NB_CONFIG_DIDG+1)).style.display = 'none'
    document.getElementById('adaptation_config_val_' + (NB_CONFIG_DIDG+1)).innerHTML = ''
    document.getElementById('nouvelle_config_val_' + (NB_CONFIG_DIDG+1)).innerHTML = ''

}

set_confuguration_select = function(id,id_copy) {
    var nb_pipes, elem, i,j, opt
    if (id_copy != null) {
        document.getElementById('configure_scale_' + id).value = document.getElementById('configure_scale_' + (id_copy)).value
        SCALE[id] = SCALE[id_copy]
    }
    nb_pipes = DIDG_INFOS[DIDG_NAME[id]]['nb_config_pipes']
    if (nb_pipes < 6) {
        for (i=(nb_pipes + 1);i<= 6;i++) {
            document.getElementById('length_div_' + id + '_' + i).style.display = "none"
            elem = document.getElementById('pipe_col_' + i + '_' + id)
            if (elem)
                elem.style.display = "none"
        }
    }
    for (i=1;i<=nb_pipes;i++) {
        elem = document.getElementById('length_div_' + id + '_' + i)
        elem.style.display = "block"
        elem = document.getElementById('pipe_col_' + i + '_' + id)
        if (elem) {
            elem.style.display = "block"
        }
        elem = document.getElementById('length_' + id + '_' + i)
        while (elem.options.length > 0) {
            elem.remove(elem.options.length - 1)
        }
        for (j = 0;j<=Math.round(DIDG_INFOS[DIDG_NAME[id]]['config_sizes'][i - 1] / SCALE[id]);j++) {
            opt = document.createElement('option');
            opt.text = j;
            opt.value = j;
            elem.add(opt, null);
        }
        if (i <= nb_pipes - 3)
            elem.value = Math.round(DIDG_INFOS[DIDG_NAME[id]]['config_sizes'][i - 1] / (4 * SCALE[id]))
        else
            elem.value = Math.round(DIDG_INFOS[DIDG_NAME[id]]['config_sizes'][i - 1] / (2 * SCALE[id]))
    }
}

set_config_values = function(id,config) {
    var i
    if (config){
        for (i =1;i<=config.length;i++) {
            if (SCALE[id] == SCALE_CM)
                document.getElementById('length_' + id + '_' + i).value = Math.round(config[i - 1] / 10)
            else
                document.getElementById('length_' + id + '_' + i).value = config[i - 1]
        }
    }
}

etalonner_config = function(id) {
    var top
    document.getElementById('samples_col_' + id).style.display = 'block'
    document.getElementById('sample_0_' + id+'_cell').style.display = 'block'
    document.getElementById('sample_1_' + id+'_cell').style.display = 'block'
    document.getElementById('sample_2_' + id+'_cell').style.display = 'block'
    document.getElementById('sample_3_' + id+'_cell').style.display = 'block'
    document.getElementById('confirm_sample_' + id).style.display = 'block'
    document.getElementById('annuler_etalonner_' + id).style.display = 'inline'
    document.getElementById('etalonner_' + id).style.display = 'none'
    if (document.getElementById('mobile_version')) {
        if (document.getElementById('didgeridoo_configuration_' + id)) {
            top = document.getElementById('didgeridoo_configuration_' + id).getBoundingClientRect().y + (window.pageYOffset || document.documentElement.scrollTop)
        }else {
            top = document.getElementById('config_' + id).getBoundingClientRect().y + scroll_add()
        }
        window.scrollTo({
            top: top,
            left: 0,
            behavior: 'smooth'
        })
    }
}

submit_sample_config = function(id) {
    var flag,req,info,i,freqs = read_sample_config(id)
    flag = false
    for (i = 0;i<=2;i++) {
        if (freqs[i])
            flag = true
    }
    if (flag) {
        document.getElementById('etalonnage_' + id).style.display = 'block'
        document.getElementById('adaptation_config_' + id).style.display = 'none'
        document.getElementById('nouvelle_config_' + id).style.display = 'none'
        document.getElementById('etalonnage_loading_' + id).style.display = 'block'
        config = read_didg_config(id)
        req = HOST + "/tuner/etalonner"
        info = 'didg_name=' + DIDG_NAME[id]
        for (i=1;i<=DIDG_INFOS[DIDG_NAME[id]]['nb_config_pipes'];i++) {
            info += "&l" + i + '=' + config[i - 1]
        }
        for (i=0;i<freqs.length ;i++) {
            info += "&f" + i + '=' + freqs[i]
        }
        window.scrollTo({
            top: document.getElementById('didgeridoo_configuration_' + id).getBoundingClientRect().y + scroll_add(),
            left: 0,
            behavior: 'smooth'
        })
        Rails.ajax({
            url: req,
            type: "POST",
            data: info,
            success: function(response) { treat_response_sample(response, id)}
        });
    }else {
        send_message_jui(I18n.t("messages.error_encode_sample"))
    }
}

submit_sample_select = function(id) {
    var flag, i, config, req, info, freqs = read_sample_config(id)
    flag = false
    for (i = 0; i < 3; i++) {
        if (freqs[i])
            flag = true
    }
    if (flag) {
        document.getElementById('etalonnage_' + id).style.display = 'block'
        document.getElementById('adaptation_config_' + id).style.display = 'none'
        document.getElementById('nouvelle_config_' + id).style.display = 'none'
        document.getElementById('etalonnage_loading_' + id).style.display = 'block'
        config = read_didg_config_select(id)
        req = HOST + "/tuner/etalonner"
        info = 'didg_name=' + DIDG_SELECT
        for (i =1;i<=DIDG_INFOS[DIDG_SELECT]['nb_config_pipes'];i++) {
            info += "&l" + i + '=' + config[i - 1]
        }
        for (i=0;i<freqs.length;i++) {
            info += "&f" + i + '=' + freqs[i]
        }
        window.scrollTo({
            top: document.getElementById('config_' + id).getBoundingClientRect().y + scroll_add(),
            left: 0,
            behavior: 'smooth'
        })
        Rails.ajax({
            url: req,
            type: "POST",
            data: info,

            success: function(response)  {treat_response_sample(response, id)}
        });
    }else {
        send_message_jui(I18n.t("messages.error_encode_sample"))
    }
}

treat_response_sample = function(response,id) {
    document.getElementById('etalonnage_loading_' + id).style.display = 'none'
    if (response.hasOwnProperty('json')) {
        document.getElementById('adaptation_config_' + id).style.display = 'block'
        document.getElementById('nouvelle_config_' + id).style.display = 'block'

        update_adaptation(response['json'], id)
    }else {
        send_message_jui(I18n.t("messages.error_calibrating"))
    }
}

update_adaptation = function(response,id) {
    document.getElementById('adaptation_config_val_' + id).innerHTML = String(response['adaptation_config']).replace(/,/g,', ')
    document.getElementById('nouvelle_config_val_' + id).innerHTML = String(response['nouvelle_config']).replace(/,/g,', ')
    document.getElementById('etalonnage_request_id_' + id).innerHTML = response['etal_req_id']
    document.getElementById('calibrate_improve_' + id).style.display = 'block'
}

calibrate_improve = function(id,value) {
    var req, info, etal_req_id = document.getElementById('etalonnage_request_id_' + id).innerHTML
    if (etal_req_id != "") {
        req = "/phonowave/improved_frequence"
        info = "etal_req_id=" + etal_req_id + "&improve=" + value
        Rails.ajax({
            url: req,
            type: "POST",
            data: info,
        });
    }
    document.getElementById('calibrate_improve_' + id).style.display = 'none'
}

sample_freq_cents = function(id) {
    var cents,i,freq,ratio,samples = read_sample_config(id)
    cents = []
    for (i =0;i<samples.length;i++){
        if (samples[i]) {
            freq = parseFloat(document.getElementById('freq_' + id + '_' + i + '_config').innerHTML)
            ratio = samples[i] / freq
            cents.push(100 * (ratio - 1) / (COEF_NOTE - 1))
        } else {
            cents.push(null)
        }
    }
    return cents
}

sample_freq_cents_select = function(id) {
    var cents,i,freq,ratio,samples = read_sample_config(id)
    cents = []
    for (i =0;i<samples.length;i++) {
        if (samples[i]) {
            freq = parseFloat(document.getElementById('freq_' + i + '_config_' + id).innerHTML)

            ratio = samples[i] / freq
            cents.push(100 * (ratio - 1) / (COEF_NOTE - 1))
        }else {
            cents.push(null)
        }
    }
    return cents
}

read_sample_config = function(id) {
    var i, val, freq,freqs = []
    for (i =0;i<4;i++) {
        val = document.getElementById("sample_" + i + "_" + id).value
        val = val.replace(",", ".")
        freq = parseFloat(val)
        if (freq) {
            freqs.push(freq)
        }else {
            document.getElementById("sample_" + i + "_" + id).value = ""
            freqs.push(null)
        }
    }
    return freqs
}

deactivate_tuner = function(id) {
    var max,i,flag = true
    if (PAGE == 'configure')
        max = MAX_CONFIGS
    else
        max = MAX_SELECT
    for (i=0;i<max;i++) {
        if (i != id && document.getElementById('samples_col_' + i).style.display != 'none')
            flag = false
    }
    document.getElementById('samples_col_' + id).style.display = 'none'
    document.getElementById('sample_0_' + id + '_cell').style.display = 'none'
    document.getElementById('sample_1_' + id + '_cell').style.display = 'none'
    document.getElementById('sample_2_' + id + '_cell').style.display = 'none'
    document.getElementById('sample_3_' + id + '_cell').style.display = 'none'
    document.getElementById('confirm_sample_' + id).style.display = 'none'
    document.getElementById('annuler_etalonner_' + id).style.display = 'none'
    document.getElementById('etalonner_' + id).style.display = 'inline'
}

browser_pitch_detect_ok = function() {
    if(navigator.userAgent.indexOf("Chrome") != -1 || navigator.userAgent.indexOf("Firefox") != -1){
        return true
    }
    return false
}

toggle_harmonics = function(id) {
    if (document.getElementById('laptop_version')) {
        document.getElementById('toggle_harmonics_' + id).style.display = 'none'
        document.getElementById('toggle_impedance_' + id).style.display = 'block'
        document.getElementById('config_' + id + '_harmonics').style.display = 'block'
        document.getElementById('config_' + id + '_impedance').style.display = 'none'
    }
}

toggle_impedance = function(id) {
    if (document.getElementById('laptop_version')){
        document.getElementById('toggle_harmonics_' + id).style.display = 'block'
        document.getElementById('toggle_impedance_' + id).style.display = 'none'
        document.getElementById('config_' + id + '_harmonics').style.display = 'none'
        document.getElementById('config_' + id + '_impedance').style.display = 'block'
    }
}
