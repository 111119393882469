
/*I18n = require("i18n-js");
I18n.locale = 'fr'*/


/*trans_test = function(){
    alert(I18n.locale)
    alert(I18n.t("phonodidg.index.trans_test_2"))
}*/

init_recaptcha = function() {
    var elem = document.getElementById("submit_contact");
    elem.addEventListener("click",check_recaptcha);
}

// treat_recaptcha_res = function(res) {
//   alert("ok");
//   alert( JSON.stringify( res ) );
// }

check_recaptcha = function(event) {
    var cap = grecaptcha.getResponse();
    if (cap.length == 0){
      send_message_jui(I18n.t("messages.error_recaptcha"))
      event.preventDefault();
    }
    // else {
    //   var url = 'https://www.google.com/recaptcha/api/siteverify';
    //   var info = {'secret':'','response':cap}
    //   alert(url);
    //   fetch(url, { method: 'POST',body:JSON.stringify(info), mode: 'no-cors'})
    //     .then(response => response.json())
    //     .then(commits => alert("ok"));
    //     //.then(res => {treat_recaptcha_res(res.json())})
    //   // .then(response => {
    //   //   alert("ok")
    //   //   alert(Object.keys(response).length)
    //   //   alert(JSON.stringify(response.json()))
    //   // })
    //   // .then(data =>{
    //   //   alert("ok");
    //   // })
    //
    //
    //   // Rails.ajax({
    //   //     url : req,
    //   //     type : "GET",
    //   //     data : info,
    //   //     success: function(response) {
    //   //       alert(response['success'])
    //   //     }
    //   // });
    // }
    // alert("prevent")
    // event.preventDefault();
}

toggle_mobile_menu = function() {
    var x = document.getElementById("menu_mobile_links");
    if (x.style.display === "block") {
        x.style.display = "none";
    } else {
        x.style.display = "block";
    }
}

toggle_tuto_mobile = function(elem) {
    // elem = document.getElementById(id)
    // alert(elem)
    if (elem.style.display == 'block') {
        elem.style.display = 'none';
    } else {
        elem.style.display = 'block';
    }
}

show_models = function(id) {
    $('#didg_carousel').trigger('destroy.owl.carousel');
    $("#didg_carousel").owlCarousel({
        items: 3,
        startPosition:id,
        loop:true,
    });
    window.scrollTo({
        top: document.getElementById('didg_carousel').getBoundingClientRect().y + (window.pageYOffset || document.documentElement.scrollTop),
        left: 0,
        behavior: 'smooth'
    });
}

show_models_mobile = function(id) {
    $('#didg_carousel').trigger('destroy.owl.carousel');
    $("#didg_carousel").owlCarousel({
        items: 1,
        startPosition:id,
        loop:true,
    });
    window.scrollTo({
        top: document.getElementById('didg_carousel').getBoundingClientRect().y + (window.pageYOffset || document.documentElement.scrollTop),
        left: 0,
        behavior: 'smooth'
    });
}
