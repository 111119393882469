// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
global.Rails = Rails;
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("packs/phonodidg")
require("packs/didg_tuner")
require("packs/user")

import "owl.carousel"
import 'owl.carousel/dist/assets/owl.carousel.css';

import "chart.js"

import I18n from 'i18n-js'
I18n.locale = 'fr'
window.I18n = I18n

import 'src/stylesheets/application'
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
