NB_PERSO_CONFIG = 0

init_didgs_user = function(nb_configs) {
    NB_PERSO_CONFIG = nb_configs
    if (nb_configs > 0) {
        var i;
        for (i=0;i<nb_configs;i++) {
            draw_didg(i, 'didg_canvas_' + i, read_user_config(i), read_user_didg_name(i))
            document.getElementById("save_name_" + i).style.display = 'none'
            document.getElementById("save_comment_" + i).style.display = 'none'
            document.getElementById("rename_field_" + i).style.display = 'none'
            document.getElementById("comment_field_" + i).style.display = 'none'
        }
    }
}

read_user_didg_name = function(id) {
    return document.getElementById('user_didg_name_'+id).innerHTML;
}

read_user_config = function(id) {
    var i,res = document.getElementById('user_config_'+id).innerHTML.split(',');
    for (i=0;i<res.length;i++){
        res[i] = parseInt(res[i])
    }
    return res
}

rename = function(id) {
    document.getElementById("rename_" + id).style.display = 'none'
    document.getElementById("name_config_" + id).style.display = 'none'
    document.getElementById("save_name_" + id).style.display = 'block'
    document.getElementById("rename_field_" + id).style.display = 'block'
}

config_name_used = function(id,name) {
    var i;
    for (i=0;i<NB_PERSO_CONFIG;i++) {
        if (i != id && document.getElementById('name_config_' + i + '_span').innerHTML == name) {
            return true
        }
    }
    return false
}

save_name = function(id,id_user_config) {
    var name = document.getElementById('input_config_' + id).value
    if (config_name_used(id,name)) {
        send_message_jui(I18n.t("messages.name_used"))
    }else if (valid_input(name)) {
        req = HOST + "/rename_user_config"
        var info = "id_user_config=" + id_user_config + "&name=" + name
        Rails.ajax({
            url: req,
            type: "POST",
            data: info,
            success: function () {
                document.getElementById('name_config_' + id + '_span').innerHTML = name
            }
        });

        document.getElementById("save_name_" + id).style.display = 'none'
        document.getElementById("rename_field_" + id).style.display = 'none'
        document.getElementById("rename_" + id).style.display = 'block'
        document.getElementById("name_config_" + id).style.display = 'block'
    } else {
        send_message_jui(I18n.t("messages.forbidden_char"))
    }
}

comment = function(id) {
    document.getElementById("comment_" + id).style.display = 'none'
    document.getElementById("comment_config_" + id).style.display = 'none'
    document.getElementById("save_comment_" + id).style.display = 'block'
    document.getElementById("comment_field_" + id).style.display = 'block'
}

save_comment = function(id,id_user_config) {
    var comment = document.getElementById('comment_area_' + id).value
    if (valid_input(comment)) {
        req = HOST + "/change_comment_user_config"
        info = "id_user_config=" + id_user_config + "&comment=" + comment
        Rails.ajax({
            url: req,
            type: "POST",
            data: info,
            success: function() {
                document.getElementById('comment_config_' + id).innerHTML = comment
            }
        });

        document.getElementById("save_comment_" + id).style.display = 'none'
        document.getElementById("comment_field_" + id).style.display = 'none'
        document.getElementById("comment_" + id).style.display = 'block'
        document.getElementById("comment_config_" + id).style.display = 'block'
    } else {
        send_message_jui(I18n.t("messages.forbidden_char"))
    }
}

move_user_config = function(id1,id2) {
    var req = HOST + "/swap_user_config_order"
    var info = "id1=" + id1 + "&id2=" + id2
    Rails.ajax({
        url : req,
        type : "POST",
        data : info,
        success: function() {
            window.location.reload()
        }
    });
}